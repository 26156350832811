<template>
  <div v-if="statusAuth" class="row">
      <span class="name"> {{ fullname }}</span>
      <ul class="nav-top__actions">
        <li>
          <a :href="(this.$route.fullPath == '/' ? '' : this.$route.fullPath) + '/?logout=yes'">Выйти</a>
        </li>
      </ul>
  </div>

</template>

<script>
export default {
  name: "NavTopActions",
  computed: {
    fullname() {
      return this.$store.getters.fullName;
    },
    statusAuth() {
      return this.$store.getters.statusAuth !== 'noAuth'
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  font-size: 18px;

  .name {
    color: $text-gray;
  }


}
.nav-top__actions {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 30px;
  }
}

@include md-max {
  .row {
    font-size: 14px;
  }
}
</style>
