export default {
    state: {
        data: {
            title: "",
            menuBottom: [
                ],
            menuСopyright: {
            },
            menu_support_buttons: {
            }
        },
        status: "",
        errors: "",
    },
    getters: {
        title: s => s.data.title,
        menuСopyright: s => s.data.menuСopyright,
        menuBottom: s => s.data.menuBottom,
        supportButtons: s => s.data.menu_support_buttons
    },
    mutations: {
        setMenuState(state, data) {
            state.data = data.data;
            state.status = data.status;
            state.errors = data.errors;
        },
    },
    actions: {
        async setMenu({commit}) {
            try {
                await axios.get('menuitems/').then((response) => {
                    if(response.status !== "error") {
                        commit('setMenuState', response.data);
                    }
                    else
                        commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                    console.log(error);
                });

            } catch (e) {
                throw e
            }
        }
    }
}
