<template>
  <div class="footer-nav mb-4 mb-sm-0">
    <ul class="footer-nav__list">
      <li v-for="(item, index) in menu" :key="index.id">
        <a v-if="item.link" class="footer-nav__link" :href="item.link" target="_blank">
          {{ item.name }}
        </a>
        <router-link v-else-if="item.url" class="footer-nav__link" :to="item.url">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    props: {
      menu: {
        type: Array,
        require: true
      }
    },
    components: {
    },
    data: () => ({

    }),
    methods: {
      accordion(event) {
        if(window.innerWidth < 576) {
          if(event.target.tagName == "I")
            event.target.parentElement.classList.toggle('active');
          else
            event.target.classList.toggle('active');
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  .footer-nav {
    //width: 32%;
    //flex: 0 0 32%;
    margin-right: 2%;

    &:last-child {
      margin-right: 0;
    }

    .footer-nav__title {
      color: #818c99;
      font-size: 16px;
      //margin-bottom: 20px;
      font-weight: normal;

      align-items: center;
      flex: 1 0 auto;
      justify-content: inherit;
      line-height: normal;
      position: relative;
    }

    .footer-nav__list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 17px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer-nav__link {
      text-decoration: none;
      color: #031933;

    }

    .footer-nav__title .icon {
      display: none;
    }


    @include sm-max {
      .footer-nav__list {
        margin-top: 15px;
      }

        .footer-nav__list li {
          line-height: 100%;
          margin-bottom: 5px;
        }
      //.footer-nav__title {
      //  position: relative;
      //  cursor: pointer;
      //}
      //
      //.footer-nav__title .icon{
      //  align-items: center;
      //  display: inline-flex;
      //  justify-content: center;
      //  letter-spacing: normal;
      //  line-height: 1;
      //  position: relative;
      //  text-indent: 0;
      //  transition: .3s cubic-bezier(.25,.8,.5,1),visibility 0s;
      //  vertical-align: middle;
      //  margin-left: 25px;
      //  transform: rotate(180deg);
      //}
      //
      //.footer-nav__title.active .icon{
      //  transform: rotate(0deg);
      //}
      //
      //
      //.footer-nav__list {
      //  display: none;
      //}
      //.footer-nav__title.active + .footer-nav__list{
      //  display: block !important;
      //}
    }


  }
</style>
