<template>
  <div>
    <div class="app-main-layout">
      <Header />

      <main class="app-content">
        <div class="app-page">
          <router-view/>
        </div>
      </main>

      <Support />

      <Footer />


    </div>
  </div>
</template>

<script>

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Support from "@/components/support/Support";

export default {
  name: 'main-layout',
  data: () => ({
  }),
  mounted () {
  },
  components: {
    Support,
    Footer,
    Header
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.app-main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;

  .app-content {
    flex-grow: 1;
    //display: flex;
    //align-items: center;
    //justify-content: center;
  }

  .footer-block {
    //margin-top: auto;
  }

}

</style>
