<template>
  <div class="footer-block">
    <div class="footer">
      <div class="wrapper text-center text-left-sm">
        <div class="row">
          <div class="col-sm-4">
            <Logo class="text-center text-left-sm log" />
          </div>
          <div class="col-sm-4">
            <FooterNav :menu="menuBottom.slice(0, Math.ceil(menuBottom.length / 2))" />
          </div>

          <div class="col-sm-4">
            <FooterNav :menu="menuBottom.slice(Math.ceil(menuBottom.length / 2), menuBottom.length)" />
          </div>

        </div>
      </div>
    </div>

    <NavBottom/>
  </div>
</template>

<script>
  import Logo from '@/components/header/Logo'
  import FooterNav from '@/components/footer/FooterNav';
  import NavBottom from '@/components/footer/NavBottom';


  export default {
    components: {
      FooterNav,
      Logo,
      NavBottom
    },
    data: () => ({
    }),
    computed: {
      menuBottom() {
        return this.$store.getters.menuBottom;
      }
    },
    methods: {

    }
  }

</script>

<style lang="scss" scoped>
  .footer {
    padding: 60px 0 100px;
    margin-top: 95px;
    background-color: $bg-light;

    .footer-nav-block {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      .footer-right-col {
        //width: 300px;
        //flex: 0 0 300px;

        display: flex;
        flex-direction: column;
      }

      .footer-left-col {
        flex-grow: 1;
        display: flex;
      }

      .footer-support__list {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-top: 14px;

        li {
          margin-bottom: 13px;
        }
      }

      .footer-support__link {
        text-decoration: none;

      }

      .footer-support__feedback {
        margin-top: auto;
        color: #031933;
        text-decoration: none;
      }

      .footer-support__title {
        font-size: 18px;
      }
    }




    @include sm-max {
      .log {
        display: inline-flex;
      }
    }



    @include md-max {
      margin-top: 40px;
      padding-bottom: 30px;
      padding-top: 30px;

      .footer-right-col {
        margin-top: 20px;
      }

      .footer-support__list {
        padding-top: 0px !important;
      }
    }

  }

</style>
