
import store from '@/store';

export default {
    state: {
        "data": {
            "users": [
            ]
        },
        roles: {
            "data": {
                "roles": [],
                "services": [],
                "filials": []
            },
            "status": "ok/error",
            "errors": [
            ]
        },

        checkData: {
            "data": {

            },
            "status": "",
            "errors": [
            ]
        },

        "status": "ok/error",
        "errors": [
        ]
    },
    getters: {
        usersList: s => {
            return s.data.users.map(item => {
                item.lastName = item.lastName ? item.lastName : ''
                item.name = item.name ? item.name : ''
                item.middleName = item.middleName ? item.middleName : ''
                item.fio = item.lastName  + " " + item.name + " " + item.middleName;
                return item;
            })
        },
        userRoles: s => s.roles,
        userById: s => param => {
            return s.data.users.find(obj => obj.contactId == param);
        },
        usersStatus: s => s.status,
        userErrorByName: s => param => s.checkData.errors.some(e => e.nameField == param),
        userErrorTextByName: s => param => s.checkData.errors.filter(e => e.nameField.toLowerCase() == param).map(e => e.text)[0],
        usersErrors: s => s.errors
    },
    mutations: {
        setUsers(state, data) {
            if(data == "")
                data = [];
            state.data.users = data;
        },
        setRolesData(state, data) {
            state.roles = data;
        },
        setRegNum(state, num) {
            state.regNum = num;
        },
        resetCheckData(state) {
            state.checkData.errors = [];
        },

        setCheckData(state, data) {
            state.checkData = data;
        },
    },
    actions: {
        async setRolesData({commit}) {
            try {
                await axios.get('/getRolesData').then((response) => {
                    if(response.status != "error")
                        commit('setRolesData', response.data);
                    else
                        commit('setErrorText', response.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
            } catch (e) {
                throw e
            }
        },

        async getUsers({commit}) {
            try {
                await axios.get('/getUsers/').then((response) => {
                    if(!response.data.errors.length) {
                        commit('setUsers', response.data.data);
                    }
                    else
                        commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
                return true;

            } catch (e) {
                throw e
            }
        },

        async sendNewUser({commit}, formdata) {
            try {
                return await axios.get(`/addWorker?name=${formdata.name}&last_name=${formdata.last_name}&second_name=${formdata.second_name}&email=${formdata.email}`).then((response) => {
                    commit('setCheckData', response.data);
                    if(response.data.errors.length)
                        commit('setErrorText', response.data.errors)
                    return response.data;
                }).catch((error) => {
                    console.log(error);
                });
            } catch (e) {
                throw e
            }
        },

        async sendUser({commit}, formdata) {
            try {
                return await axios.get(`/addWorkerRole?contactId=${formdata.contactId}&superadmin=${formdata.superadmin}${formdata.roles}&filialId=${formdata.filialId}`).then((response) => {

                    if(response.data.errors.length)
                        commit('setErrorText', response.data.errors)
                    return response.data;
                }).catch((error) => {
                    console.log(error);
                });
            } catch (e) {
                throw e
            }
        },

        async updateUser({commit}, formdata) {
            try {
                return await axios.get(`/editUserRole?contactId=${formdata.contactId}&filialId=${formdata.filialId}${formdata.roles}`).then((response) => {
                    if(response.data.errors.length)
                        commit('setErrorText', response.data.errors);

                    return response.data;
                }).catch((error) => {
                    console.log(error);
                });
            } catch (e) {
                throw e
            }
        },

        async deleteUser({commit}, idData) {
            try {
                return await axios.get(`/deleteRoleUser?delete=Y&contactId=${idData.id}&filialId=${idData.filial}&superadmin=${idData.superadmin}`).then((response) => {
                    if(!response.data.errors.length) {
                        this.dispatch("getUsers");
                    } else
                        commit('setErrorText', response.data.errors)

                    return response.data;
                }).catch((error) => {
                    console.log(error);
                });

            } catch (e) {
                throw e
            }
        },


        // async deleteSuperAdmin({commit}, idData) {
        //     try {
        //         if(store.getters['superAdmin']) {
        //
        //             return await axios.get(`/removeSuperMark/?contactId=${idData.id}&super=Y`).then((response) => {
        //             if(!response.data.errors.length) {
        //                 this.dispatch("getUsers");
        //             } else
        //                 commit('setErrorText', response.data.errors)
        //
        //                 return response.data;
        //             }).catch((error) => {
        //                 console.log(error);
        //             });
        //         }
        //         else
        //             return false;
        //
        //     } catch (e) {
        //         throw e
        //     }
        // }


    }
}
