<template>
  <div>
  <div class="wrapper">
    <div v-if="$route.name !== 'support'" class="container support-block">
      <div class="row  align-center">
        <div class="col-md-6">
          <div class="support-left-col text-center text-left-md">
            <h2 class="support-title title-h2">{{ menuItems.name }}</h2>
            <div class="support-text">{{ menuItems.text }}</div>
          </div>
        </div>
        <div class="col-md-6 text-center text-right-md">
          <div class="support-right-col align-center" v-if="menuItems.buttons && menuItems.buttons.length > 0">
            <template v-for="(item, index) in menuItems.buttons">
              <router-link v-if="item.url && !item.link && item.text" class="btn btn-border support-btn"
                           :to="item.url"  :key="item.id">
                {{ item.text }}
              </router-link>
              <a v-if="item.link && item.text" class="btn btn-border support-btn" :href="item.link" target="_blank"  :key="item.id">{{ item.text }}</a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

  export default {
    components: {
    },
    computed: {
      menuItems() {
        return this.$store.getters['supportButtons']
      }
    },
  }

</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin-top: 110px;
  box-sizing: border-box;
}
  .support-block {
    background-color: $bg-light;
    padding: 55px;
    border-radius: 8px;

    .support-title {
      margin-bottom: 10px;
    }

    .support-text {
      font-size: 22px;
    }

    .support-btn {
      margin-left: 10px;
      &:hover {
        background: #1787d8;
        color: #fff;
      }
    }


    @include lg-max {
      .support-btn {
        margin-top: 10px;
      }
    }

  }


@include md-max {
  .container {
    margin-top: 30px;
  }
  .title-h2 {
    font-size: 24px;
  }



  .support-block {
    padding: 30px;

    .support-text {
      font-size: 18px;
    }

  }

}
</style>
