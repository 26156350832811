

export default {
    state: {
        login: {
            name: "Партнер",
            text: "Авторизуйтесь через НЭБ Паспорт, чтобы войти на сайт",
            infoLink: {
                name: "Что такое НЭБ Паспорт?",
                link: "https://www.google.com/"
            },
            buttons: {
                auth: {
                    name: "Войти",
                    link: "https://www.google.com/"
                },
                reg: {
                    name: "Зарегистрироваться",
                    link: "https://www.google.com/"
                },
                forgotPassword: {
                    name: "Забыли пароль?",
                    link: "https://www.google.com/"
                }
            }
        }
    },
    getters: {
        login: s => s.login
    },
    mutations: {
        setLog(state, data) {
            state.login = data;
        },
    },
    actions: {
        async getLog({commit}) {
            try {
                await axios.get('loginUrl').then((response) => {
                    if(response.data.status != "error")
                        commit('setLog', response.data.data);
                }).catch((error) => {
                    console.log(error);
                });
                //commit('setLog', []);

            } catch (e) {
                throw e
            }
        }
    }
}
