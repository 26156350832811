<template>
  <div class="nav-bottom">
    <div class="wrapper">
      <div class="row space-between">
        <div class="col-sm-6">
          <p> {{ menuСopyright.name }} </p>
        </div>
        <div class="col-sm-6 text-left text-right-sm">
          <a href="#"> Разработано </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      menuСopyright() {
        return this.$store.getters.menuСopyright
      }
    },
  }

</script>

<style lang="scss" scoped>
  .nav-bottom {
    width: 100%;
    padding: 9px 0;
    color: $text-gray;
    font-size: 16px;
    background-color: $bg-footer;
  }


</style>
