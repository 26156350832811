<template>
  <div class="login-form">
    <div class="error-text">{{ login.text }}</div>
    <a class="btn btn-border" :href="login.buttons.reg.link">{{ login.buttons.reg.name }}</a>
    <a class="btn btn-bg" :href="login.buttons.auth.link">{{ login.buttons.auth.name }}</a>
    <a :href="login.buttons.forgotPassword.link">{{ login.buttons.forgotPassword.name }}</a>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  props: ['login']
}
</script>

<style scoped lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  width: 387px;
  max-width: 100%;
  margin-bottom: 20px;
}
</style>
