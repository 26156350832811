import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library,
  dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import endingWord from '@/filters/endings.filter'
import vClickOutside from 'v-click-outside'

const dev = process.env.NODE_ENV !== 'production'

import axios from 'axios'
window.axios = axios

axios.defaults.baseURL = dev ? 'http://b2b.support.rusneb.dev.infospice.ru/api/' : '/api/'

if (dev) {
  axios.defaults.headers.common['User-auth-header'] = '183' //1
}


dom.watch()

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.filter('ending', endingWord)

Vue.config.productionTip = false


import Vuetify from 'vuetify'
Vue.use(Vuetify)

Vue.use(vClickOutside)

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')
