export default {
    state: {
        "data": {
            "compLinked": false,
            "isIndividual": true,
            lastname: "",
            name: "",
            "superadmin": false,
            "company": {
                 "companyId": "",
                 "companyName": "",
                 "companyInn": "",
                 "companyStatus": "",
                 "companyStatusText": "",
                 "companyComment": "",
                 "services": [
                     ]
             },
             "linking": {}
            },

        "status": "ok",
        "errors": []
    },
    getters: {
        compLinked: s => s.data.compLinked,
        fullName: s => {
            const name = s.data.name
            const lastname = s.data.lastname
            if (name && lastname) {
                return s.data.name + " " + s.data.lastname
            } else if (!name && lastname) {
                return s.data.lastname
            } else if (name && !lastname) {
                return s.data.name
            } else {
                return ''
            }
        },
        isIndividual: s => s.data.isIndividual,
        company: s => s.data.company,
        companyName: s => s.data.company.companyName,
        companyStatus: s => s.data.company.companyStatus,
        companyStatusText: s => s.data.company.companyStatusText,
        superAdmin: s => s.data.superadmin,
        companyComment: s => s.data.company.companyComment,
        services: s => s.data.company.services,
        userId: s => s.data.userId,
        companyId: s => s.data.company.companyId,
        allUsersCount: s => s.data.company.allUsersCount,
        allFilialCount: s => s.data.company.allFilialCount
       },
    mutations: {
        setStateMain(state, data) {
            state.data = data.data;
            state.status = data.status;
            state.errors = data.errors;
        },
        setIndividual(state, individual) {
            state.data.isIndividual = individual;
        },
    },
    actions: {
        async setIndividual({commit}, individual) {
            try {
                await axios.post('updateContact/', {
                    "isIndividual": individual
                }).then((response) => {
                    if(response.status !== "noAuth") {
                        commit('setIndividual', individual);
                        this.dispatch("setAuth");
                    }
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                });

            } catch (e) {
                throw e
            }
        },

        async serviceConnect({commit}, id) {
            try {
                await axios.get(`/serviceConnect/${id}`).then((response) => {
                    console.log(response);
                    if(response.data.data) {
                        this.dispatch("setAuth")
                    }
                    else {
                        commit("setErrorText", response.data.errors)
                    }
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}])
                    console.log(error);
                });

            } catch (e) {
                throw e
            }
        }
    }
}
