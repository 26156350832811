<template>
  <div class="logo-title">
    <router-link class="header-logo" to="/">НЭБ</router-link>
    <h1 class="header-title">{{ title }}</h1>
  </div>
</template>

<script>

export default {
  props: ['title'],
  components: {

  },
  data: () => ({
  }),
  methods: {

  }

}
</script>

<style lang="scss" scoped>
.logo-title {
  display: flex;
  align-items: center;

  .header-title {
    font-size: 24px;
    color: $navy;
    font-weight: normal;
  }

  .header-logo {
    display: inline-block;
    margin-right: 15px;
    font-size: 24px;
    color: $navy;
    font-weight: bold;
    text-decoration: none;
  }

}
</style>
