<template>
  <div class="login-page">
    <LoginForm :login="login"/>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm"
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data: () => ({
  }),
  mounted() {
    this.$store.dispatch("getLog");
  },
  computed: {
    login () {
      return this.$store.getters.login
    }
  }
}
</script>
<style scoped lang="scss">
.login-form {
  margin-top: -20px;
}
</style>
