export default {
    state: {
        regNum: 0,
        company: {
            status: "ok",
            errors: [
            ],
            data: {
                companies: [],
                count: 0
            }
        }
    },
    getters: {
        regNum: s => s.regNum,
        companyData: s => s.company.data.companies,
        companyCount: s => s.company.data.count,
        errors: s => s.company.errors,
        companyDataByName: s => param => s.company.data.companies.filter(e => e.name == param)[0].info,
        errorByName: s => param => s.company.errors.some(e => e.nameField == param),
        errorTextByName: s => param => s.company.errors.filter(e => e.nameField == param).map(e => e.text)[0],
    },
    mutations: {
        setState(state, data) {
            state.company = data;
        },
        setRegNum(state, num) {
            state.regNum = num;
        },
    },
    actions: {
        async sendFormData({commit}, company) {
            try {
                await axios.post('/company/add/', company).then((response) => {
                    console.log(response);
                    commit('setRegNum', response.data.data.dealId);
                    commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
                return true;

            } catch (e) {
                throw e
            }
        },

        async sendInn({commit}, payload) {
            try {
                await axios.post('/company/checkInn/', {
                    "inn": payload.inn,
                    "kpp": payload.kpp
                }).then((response) => {
                    console.log(response.data);
                    commit('setState', response.data);
                    commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });

            } catch (e) {
                throw e
            }
        }
    }
}
