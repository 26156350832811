<template>
  <div>
    <WarningModal />

    <div class="wrapper">
      <div class="row space-between">
        <Logo />
        <NavTopActions class="actions" />
        <Burger class="burger" style="width: max-content;" :isBurgerActive = "isBurgerActive" @activeMenu="activedMenu()" />
      </div>
    </div>
    <MainHeaderNav v-if="showBtn" />



    <transition name="slide">
      <DropdownMenu class="dropdown" v-if="activeMenu" @close="toggleButton()" v-click-outside="toggleButton"/>
    </transition>

  </div>
</template>

<script>

import WarningModal from "@/components/header/WarningModal";
import Logo from "@/components/header/Logo";
import NavTopActions from "@/components/header/NavTopActions";
import MainHeaderNav from "@/components/header/MainHeaderNav";
import Burger from "@/components/header/Burger";
import DropdownMenu from "@/components/header/DropdownMenu";
export default {
  components: {
    DropdownMenu,
    Burger,
    MainHeaderNav,
    NavTopActions,
    Logo,
    WarningModal

  },
  data: () => ({
    isBurgerActive: false,
    activeMenu: false
  }),
  computed: {
    showBtn() {
      return this.$store.getters.compLinked;
    }
  },
  methods: {
    toggleButton() {
      this.activeMenu = false
      this.isBurgerActive = false
    },
    activedMenu() {
      this.isBurgerActive = !this.isBurgerActive
      this.activeMenu = !this.activeMenu
    }
  }

}
</script>

<style lang="scss" scoped>
.wrapper > .row {
  line-height: normal;
  align-items: center;
  border-bottom: 2px solid $light-border;
  padding: 25px 0px;
}

.burger, .dropdown {
  display: none;
}


// slide анимация

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@include md-max {
  .actions {
    display: none;
  }
  .burger, .dropdown {
    display: block;
  }
}
</style>
