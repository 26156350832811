<template>

  <div class="item">
    <h3 :class="{fade: (serviceStatus === 'notConnected') || (!isIndividual && !compLinked)}"> {{ name }} </h3>


    <div class="status_wrap" v-if="superadmin && serviceStatus === 'connected' && companyContractId">
      <span>Договор № {{ companyContractId[0] }} </span>
      <span class="status" :class="serviceStatus"> </span>
    </div>


    <!--    <div class="status_wrap" v-if="compLinked && !superadmin && !isIndividual && serviceStatus == 'connected' && companyContractId">-->
    <!--      <span>Подключено</span>-->
    <!--      <span class="status" :class="serviceStatus"> </span>-->
    <!--    </div>-->


    <div class="status_wrap"
         v-if="(statusText && superadmin && serviceStatus !== 'check' && !companyContractId) ||
                (statusText && serviceStatus !== 'check' && !superadmin)" >
      <span>{{ statusText }} </span>
      <span class="status" :class="serviceStatus"> </span>
      <div v-if="!isIndividual && isCompanyIndividual&&!compLinked">(<router-link to="/add">привязать</router-link>)</div>
    </div>

    <div class="stat" v-if="false && stat.length && serviceStatus === 'connected'">
      <p>Статистика</p>
      <div class="space-between" v-for="(stats, ind) in stat" :key="ind">
                <span>
                  {{ stats.name }}:
                  {{ stats.value }}
                </span>

        <a :href="stats.controlStatLink"
           v-if="(isIndividual && !compLinked && ind == 0)
           || (compLinked && superadmin  && ind == 0)">Управлять</a>
      </div>
    </div>

    <div class="personal"
         v-if="serviceStatus === 'connected'">
      <p>Сотрудники</p>
      <div class="space-between">
                <span>
                  Пользователей:
                  {{ usersCount }}
                </span>
        <router-link to="/employers/"
                     v-if="(isIndividual && !compLinked)
                        || (compLinked && superadmin)">Управлять</router-link>
      </div>
    </div>


    <div class="reg"
         v-if="regId && serviceStatus === 'check'">
      <p>Заявка № {{ regId }}</p>
      <span class="status" :class="serviceStatus"> {{ regStatus }} </span>
    </div>


    <router-link v-if="btnQuesLink && serviceStatus === 'check'"
                 class="btn btn-border" :to="btnQuesLink"> Задать вопрос </router-link>


    <router-link v-if="serviceStatus === 'notConnected' && btnHelpLink"
                 :to="btnHelpLink">Процедура подключения</router-link>
    <button
        v-if="(serviceStatus === 'notConnected') || (!isIndividual && !compLinked)"
        class="btn btn-bg"
        ref="script-before"
        :class="[{'disabled': !isIndividual && ((isCompanyIndividual && !compLinked) || (companyStatus === 'check') || !companyStatus)}]"
        :disabled="!isIndividual && ((isCompanyIndividual && !compLinked) || (companyStatus === 'check') || !companyStatus)"
        @click="handleClick"> {{ btnName ? btnName : "Подключиться"  }}  </button>

    <!-- rkp only -->
    <a v-if="id == 16706 && serviceStatus === 'connected' && stat && stat.length && stat[0]['controlStatLink']"
       :href="stat[0]['controlStatLink']"
       class="btn btn-bg" target="_blank">
      Личный кабинет РКП
    </a>

  </div>



</template>

<script>
export default {
  name: "ServiceCard",
  data() {
    return {
    }
  },
  props: {
    compLinked: {},
    isIndividual: {
      default: false
    },
    serviceStatus: {
    },
    id: {},
    superadmin: {},
    statusText: {},
    companyContractId: {},
    companyStatus: {},
    isCompanyIndividual: {},
    stat: {},
    usersCount: {},
    controlUserLink: {},
    regId: {},
    regStatus: {},
    btnQuesLink: {},
    btnHelpLink: {},
    btnName: {},
    name: {},
    crmForm: {},
    classCRM: {},
    objectCRM: {},
    useCRM: {}
  },
  mounted() {
    window.addEventListener('b24:form:init', (event) => {
      let form = event.detail.object;
      form.setProperty("IDconfigs1", this.$store.getters.userId);
      form.setProperty("IDcompany1", this.$store.getters.companyId);
    });

    setTimeout(() => {
      if(this.$refs['script-before'] && this.crmForm && this.objectCRM) {
        this.initCrmForm(this.objectCRM,
            'true',
            this.$refs['script-before'],
            this.crmForm)
      }
    }, 1000)
  },
  methods: {
    handleClick() {
      if(!this.useCRM) {
        const dataService = {
          id: this.id,
          name: this.name
        }
        this.$emit('openModal', dataService)
      }
    },
    initCrmForm(dataAtr, dataSkipMoving = 'true', el, url) {
      // создаем элемент script с указанными атрибутами перед el
      let scriptEl = document.createElement('script')
      scriptEl.setAttribute('data-b24-form', dataAtr)
      scriptEl.setAttribute('data-skip-moving', dataSkipMoving)
      el.insertAdjacentElement('beforeBegin', scriptEl);

      //функция crm формы
      (function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
      })(window,document,url)
    }
  }
}
</script>

<style scoped lang="scss">

p {
  color: $navy;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 35px;
}

@include md-max {
  p {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-decoration: none;
  min-height: 350px;
  color: #031933;
  padding: 35px 33px;
  border: 1px solid #e5e2e3;
  border-radius: 8px;
  margin-top: 32px;
  margin-right: 32px;

  h3 {
    font-weight: normal;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 24px;
  }

  .fade {
    color:$text-gray;
  }

  .status_wrap {
    font-size: 18px;
    line-height: 1.22;
    vertical-align: middle;
    margin-bottom: 39px;

    .status {
      margin-left: 12px;
      line-height: normal;
      vertical-align: initial;
    }
  }

  .reg {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 39px;
    p {
      margin-bottom: 0;
    }

    span {
      line-height: normal;
    }
  }




  .stat {
    margin-top: auto;
    p {
      font-size: 14px;
      line-height: 2;
      color: $text-gray;
      margin-bottom: 4px;
    }
    .space-between {
      display: flex;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 12px;

      span:first-of-type {
        padding-right: 5px;
      }
    }
    .space-between:last-of-type {
      margin-bottom: 0;

    }
  }


  .btn {
    margin-top: auto;
  }

  .personal {
    margin-top: 26px;
    p {
      font-size: 14px;
      line-height: 2;
      color: $text-gray;
      margin-bottom: 4px;
    }
    .space-between {
      display: flex;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 12px;
    }
    .space-between:last-of-type {
      margin-bottom: 0;

    }
  }
}
@include lg-max {
  .item {
    min-height: 270px;
  }
}

@include md-max {
  .item {
    margin-right: 0;
    padding: 25px;
    min-height: 200px;

    h3 {
      font-size: 32px;
      line-height: 0.95;
      margin-bottom: 11px;
    }


    .stat .space-between, .personal .space-between {
      font-size: 16px;
      line-height: 1.3;
    }

    .btn {
      padding: 13px;
    }

  }
}
</style>
