export default {
    state: {
        data: {
            compLinked: false,
            isIndividual: false,
            company: {},
            linking: {
            }
        },
        isShown: false,
        status: "статус",
        errors: [
            {
                "nameField": "название поля (может быть пустым)",
                "text": "текст ошибки"
            }
        ],
    },
    getters: {
        errorData: s => s.data,
        status: s => s.status,
        isIndividualMain: s => s.data.isIndividual,
        error: s => s.errors,
        isShown: s => s.isShown
    },
    mutations: {
        setErrorText(state, error) {
            if(error.length)
                state.isShown = true;
            state.errors = error;
        },
        setWarningModal(state, value) {
            state.isShown = value;
        }
    },
    actions: {
        // async setIndividual({commit}, individual) {
        //     try {
        //         // await axios.post('/', {
        //         //     "isIndividual": individual
        //         // }).then((response) => {
        //         //     console.log(response);
        //         commit('setIndividual', individual);
        //         // }).catch((error) => {
        //         //     console.log(error);
        //         // });
        //
        //     } catch (e) {
        //         throw e
        //     }
        // }
    }
}
