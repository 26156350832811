<template>
<div class="container warning" v-if="isShown && error_text.length">
  <div class="wrapper space-between">
    <div v-if="error_text.length">
      <p v-for="(item, index) in error_text" :key="index">
       {{ item.text }}
      </p>
    </div>
    <div class="close" @click="close">
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "WarningModal",
  computed: {
    error_text() {
      return this.$store.getters.error;
    },
    isShown() {
      return this.$store.getters.isShown;
    }
  },
  methods: {
    close() {
      this.$store.commit("setWarningModal", false);
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background-image: linear-gradient(to right, $warning 1%, $warning-dark);
  color: #fff;

  padding-top: 5px;
  padding-bottom: 5px;
  position: fixed;
  z-index: 999;


  .wrapper {
    display: flex;
    flex-direction: row;
    line-height: normal;
    align-items: center;

     p {
       font-size: 15px;
     }

    .close {
      min-width: 30px;
    }


    /* remove blue outline */
    button:focus {
      outline: 0;
    }

    .burger-button {
      position: relative;
      cursor: pointer;
      height: 30px;
      width: 32px;
      display: block;
      z-index: 200;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }

    .burger-bar {
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 1.5px;
      width: auto;
      margin-top: -1px;
      transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
    }



    .burger-button {
      transform: rotate(-180deg);
    }

     .burger-bar--1 {
      transform: rotate(45deg)
    }

     .burger-bar--2 {
      opacity: 0;
    }

     .burger-bar--3 {
      transform: rotate(-45deg)
    }

  }
  a {
    color: #fff;
    text-decoration: underline;
  }

}
</style>