import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import menuItems from "@/store/menuItems";
import mainError from "@/store/mainError";
import company from "@/store/company";
import main from "@/store/main";
import users from "@/store/users";
import filials from "@/store/filials";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    statusAuth: "ok"
  },
  getters: {
    statusAuth: s => s.statusAuth
  },
  mutations: {
    setAuthStatus(state, data) {
      state.statusAuth = data;
    },
  },
  actions: {
    async setAuth({commit}) {
      try {
        await axios.get('getUserInfo').then((response) => {
            commit('setStateMain', response.data);
            commit('setErrorText', response.data.errors);
            commit('setAuthStatus', response.data.status);

        }).catch((error) => {
          console.log(error);
        });

      } catch (e) {
        throw e
      }
    },
  },
  modules: {
    auth,
    company,
    main,
    users,
    filials,
    mainError,
    menuItems
  }
})
