import router from '@/router'

export default {
    state: {
        filials: [],
        "status": "ok/error",
        errors: []
    },
    getters: {
        filialsList: s => s.filials,
        filialsErrorTextByName: s => param => s.errors.filter(e => e.nameField == param).map(e => e.text)[0],
        filialById: s => param => {
            return s.filials.find(obj => obj.id == param);
        },
    },
    mutations: {
        setFilials(state, data) {
            state.filials = data;
        },
        setErrors(state, data) {
            state.errors = data;
        }
    },
    actions: {
        async getFilials({commit}) {
            try {
                await axios.get('/getFilials/').then((response) => {
                    if(!response.data.errors.length)
                        commit('setFilials', response.data.data);
                    else
                        commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
                return true;

            } catch (e) {
                throw e
            }
        },

        async addFilial({commit}, formdata) {
            try {
                await axios.post('/addFilial/', formdata).then((response) => {
                    if(response.data.errors.length) {
                        commit('setErrorText', response.data.errors);
                    } else {
                        router.push("/filials/");
                    }
                return response
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
            } catch (e) {
                throw e
            }
        },

        async updateFilial({commit}, formdata) {
            try {
                await axios.post('/updateFilial/', formdata).then((response) => {
                    console.log(formdata)
                    if(!response.data.errors.length) {
                        commit('setFilials', response.data.data);
                        router.push("/filials/");
                    }
                    else
                        commit('setErrorText', response.data.errors);
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
            } catch (e) {
                throw e
            }
        },

        async deleteFilial({commit}, id) {
            try {
                return await axios.get('/deleteFilial/' + id).then((response) => {
                    if(!response.data.errors.length) {
                        console.log(response.data)
                        commit('setFilials', response.data.data);
                    }
                    else
                        commit('setErrorText', response.data.errors);

                    return response.data;
                }).catch((error) => {
                    commit("setErrorText", [{"text": error}]);
                });
            } catch (e) {
                throw e
            }
        }


    }
}
