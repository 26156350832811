<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'

export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  components: {
    EmptyLayout,
    MainLayout
  },
  mounted () {
    const domainName = document.domain
    let crmUrl = 'support.rusneb.ru'
    if (domainName.includes('dev.infospice') || domainName.includes('local')) {
      crmUrl = 'support.rusneb.dev.infospice.ru'
    }

    this.$store.dispatch("setMenu");

    (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
      (w[b].forms=w[b].forms||[]).push(arguments[0])};
      if(w[b]['forms']) return;
      var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
    })(window,document,`https://${crmUrl}/bitrix/js/crm/form_loader.js`,'b24form');
  }
}
</script>
<style lang="scss">
@import 'assets/styles/main';
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
#app {

}
</style>
