<template>
  <div>
    <div  v-if="!statusAuth" class="content-loader">
      <Loader/>
    </div>

    <div class="wrapper" v-else-if="!showSercices && statusAuth">
      <div class="row mt">
        <div class="col-md-6">
          <p>Вы находитесь в личном кабинете партнера. Для управления проектами НЭБ необходимо привязать и подтвердить права на представительство интересов юридического лица.</p>
          <router-link v-if="!isHasError" class="btn btn-bg" to="/add">Добавить организацию</router-link>
          <p v-else class="error-text">Обратитесь в поддержку</p>
        </div>
      </div>
      <div class="row mt" v-if="!errData.isIndividual">
        <div class="col-md-6">
          <p> Если Вы планируете участвовать только в проекте «Реестр книжных памятников как физическое лицо, нажмите кнопку ниже:</p>
          <a v-if="!isHasError" class="btn btn-border" href="#" @click.prevent="setIndividual">Продолжить как физ. лицо</a>
          <p v-else class="error-text">Обратитесь в поддержку</p>
        </div>
      </div>
    </div>

    <div class="wrapper services" v-else-if="showSercices && statusAuth">

      <div class="row">

        <div v-for="(item, index) in services" :key="index"
             class="col-md-6 col-xl-4">

          <ServiceCard
              :id="item.id"
              :compLinked="compLinked"
              :isIndividual="item.isIndividualConnect"
              :serviceStatus="item.serviceStatus"
              :superadmin="superAdmin"
              :statusText="item.statusText"
              :stat="item.stat"
              :companyStatus="company.companyStatus"
              :isCompanyIndividual="isCompanyIndividual"
              :companyContractId="item.companyContractId"
              :usersCount="item.usersCount"
              :controlUserLink="item.controlUserLink"
              :regId="item.regId"
              :btnQuesLink="item.btnQuesLink"
              :btnHelpLink="item.btnHelpLink"
              :btnName="item.btnName"
              :name="item.name"
              :crmForm="item.crmForm"
              :regStatus="item.regStatus"
              :classCRM="item.classCRM"
              :objectCRM="item.objectCRM"
              :useCRM="item.useCRM"
              @openModal="openModal"/>
        </div>
      </div>

    </div>
      <v-app>
        <v-dialog v-model="dialog"  transition="dialog-bottom-transition" width="700px">
      <v-card>
        <v-toolbar>
          <v-btn icon @click.native="dialog = false">
            <v-icon>×</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <div>
            <h3 class="headline mb-0">Вы точно хотите подключить сервис {{ name }} ?</h3>
          </div>
        </v-card-title>


        <v-card-actions>
          <button class="btn btn-bg" @click="connectService">Да</button>
          <button class="btn btn-border" @click="closeModal">Нет</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-app>

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ServiceCard from "@/components/ServiceCard";
import Loader from "@/components/app/Loader";

export default {
name: "Main",
  data() {
    return {
      dialog: false,
      name: '',
      id: null
    }
  },
  components: {Loader, ServiceCard},
  mounted() {
  },
  computed: {
    errData () {
      return this.$store.getters.errorData;
    },
    company () {
      return this.$store.getters.company;
    },
    showSercices() {
      return (this.$store.getters.compLinked || this.$store.getters.isIndividual) || (!this.$store.getters.compLinked && this.company && this.company.companyStatus == "check");
    },
    isCompanyIndividual(){
      return this.$store.getters.isIndividual;
    },
    ...mapGetters(["compLinked", "superAdmin"]),
    services() {
      return this.$store.getters.services;
    },
    statusAuth() {
      return this.$store.getters.statusAuth !== 'noAuth'
    },
    usersList() {
      return this.$store.getters.usersList;
    },
    isHasError() {
      // if user hasn't id
      return this.$store.getters.error && !this.$store.getters.userId;
    }

  },
  methods: {
    async setIndividual() {
      const response = await this.$store.dispatch('setIndividual', true);
    },
    openModal (dataService) {
      this.dialog = true
      this.name = dataService.name
      this.id = dataService.id
    },
    closeModal () {
      this.dialog = false
    },
    connectService() {
      this.dialog = false;
      this.$store.dispatch("serviceConnect", this.id);
    }
  }
}
</script>

<style lang="scss">
.v-dialog__content {
  z-index: 202;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1),z-index 1ms;
  width: 100%;
  z-index: 6;
  outline: none;
}

.v-toolbar__content {
  justify-content: flex-end;
  padding: 5px 24px 10px;
  display: flex;
  color: $text-gray;

  button {
    display: inline-block;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    font-size: 40px;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    background-color: transparent;
    padding: 0px;
    color: $text-gray;
    border: none;
  }
}

.theme--light.v-divider {
  border-color: rgba(0,0,0,.12);
}
.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
}

.v-dialog>.v-card>.v-card__title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: .0125em;
  padding: 16px 24px 10px;
}

.v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);


  h3 {
    font-size: 24px !important;
  }


  .btn-border {
    background-color: transparent;
    margin-left: 25px;
  }
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.v-sheet.v-card {
  border-radius: 4px;
}
.theme--light.v-card {
  background-color: #fff;
  color: rgba(0,0,0,.87);
}
.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}
.theme--light.v-sheet {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,.87);
}
.v-dialog>* {
  width: 100%;
}
.v-card {
  border-width: thin;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow,opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
}

.v-card>:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-dialog>.v-card>.v-card__actions {
  padding: 8px 16px 25px;
}
.v-card__actions {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;

  .btn.btn-bg {
    border: 1px solid #1787d8;
  }

  .btn {
    padding: 18px 30px;
  }
}
</style>
<style scoped lang="scss">

.services .row .col-md-6 {
  display: flex;
}
.wrapper:not(.services) .row.mt {
  margin-top: 67px;
}
p {
  color: $navy;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 35px;
}

.content-loader {
  padding: 40px 0 0;
}

@include md-max {
  .row {
    margin-top: 20px;
    p {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }
}
.error-text {
  color: #e5221c;
  font-size: 16px;
}
</style>
