import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from "@/views/Main"

import store from '@/store';

const dev = process.env.NODE_ENV !== 'production'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/add/',
    name: 'Add',
    component: () => import('@/views/Add'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/result/',
    name: 'Result',
    component: () => import('@/views/AddResult'),
    meta: {
      layout: 'main'
    }
  },


  {
    path: '/employers/',
    name: 'Employers',
    component: () => import('@/views/Employers'),

    meta: {
      layout: 'main'
    }
  },
  {
    path: '/employers/adduser/',
    component: () => import('@/views/AddUser'),
    meta: {
      layout: 'main'
    }
  },

  {
    path: '/employers/adduser/:id/',
    component: () => import('@/views/AddUser'),
    meta: {
      layout: 'main'
    }
  },


  {
    path: '/filials/',
    name: 'Filials',
    component: () => import('@/views/Filials'),

    meta: {
      layout: 'main'
    }
  },

  {
    path: '/filials/addfilials/',
    component: () => import('@/views/AddFilials'),
    meta: {
      layout: 'main'
    }
  },

  {
    path: '/filials/addfilials/:id/',
    component: () => import('@/views/AddFilials'),
    meta: {
      layout: 'main',
    }
  },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})


  router.beforeEach((to, from, next) => {

    store.dispatch("setAuth").then(response => {
      //console.log(store.getters.statusAuth)
      if (store.getters.statusAuth !== "noAuth") {
        if( to.path === "/login/")
          next('/');
        else
          next();
      }
      else {
        if( to.path !== "/login/")
          next('/login/');
        else
          next();
      }
    }).catch(err => {
      if (store.getters.statusAuth !== "noAuth") {
        if( to.path === "/login/")
          next('/');
        else
          next();
      }
      else {
        if( to.path !== "/login/")
          next('/login/');
        else
          next();
      }
    });

  })



export default router
