<template>
  <div class="app-empty-layout">
    <div class="wrapper">
      <header class="header">
          <Title :title="title"/>
          <a class="header-link" :href="link.link">{{ link.name }}</a>
      </header>
    </div>
    <div class="wrapper app-empty-content">
      <router-view/>
    </div>
  </div>
</template>

<script>

import Header from "@/components/header/Header";
import Title from "@/components/header/Title";
export default {
  components: {Title, Header},
  computed: {
    title () {
      return this.$store.getters.login.name
    },
    link () {
      return this.$store.getters.login.infoLink
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.app-empty-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;

  .wrapper {
    width: 100%;
  }
  .app-empty-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  .logo-title {
    flex-grow: 1;
  }
  .header-link {
    font-size: 18px;
    text-align: right;
  }
}
</style>
