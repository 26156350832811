<template>
  <div class="wrapper">
    <div class="row space-between" v-if="compLinked">
      <ul class="kb-list">
        <li>
          <router-link to="/" class="kb-link"
                       exact-active-class="active">
            Проекты
          </router-link>
        </li>

        <li>
          <router-link to="/employers/" class="kb-link"
                       active-class="active"
                       exact-active-class="active"
                       >
            Сотрудники
          </router-link>
        </li>

        <li>
          <router-link to="/filials/" class="kb-link"
                       active-class="active"
                       exact-active-class="active">
            Филиалы
          </router-link>
        </li>
      </ul>

      <div class="company_name text-right">
        <h1> {{ companyName }}

          <span class="dropdown" v-if="companyStatus == 'connected'" @click="more = !more" >
            <div class="more text-left" v-if="more" v-click-outside="onClickOutside">
              <div class="mb more-list">
                <span>Филиалы: {{ filialsList}}<router-link to="/filials/addfilials">+</router-link></span>
                <span>Сотрудники: {{ usersList}} <router-link to="/employers/adduser">+</router-link></span>
              </div>


              <span class="actions">Действия</span>

              <ul>
                <li>
                  <router-link to="/employers/adduser">Добавить сотрудника</router-link>
                </li>

                <li>
                  <router-link to="/filials/addfilials">Добавить филиал</router-link>
                </li>
              </ul>
            </div>
          </span>

        </h1>
        <span class="status" :class="companyStatus">
          {{companyStatusText}}
        </span> <br>
        <span> {{ companyComment }} </span>
      </div>
    </div>

  </div>

</template>

<script>
export default {
name: "MainHeaderNav",
  data() {
    return {
      more: false
    }
  },
  computed: {
    companyName () {
      return this.$store.getters.companyName;
    },
    compLinked () {
      return this.$store.getters.compLinked;
    },
    isIndividual () {
      return this.$store.getters.isIndividual;
    },
    companyStatus () {
      return this.$store.getters.companyStatus;
    },
    companyStatusText () {
      return this.$store.getters.companyStatusText;
    },
    companyComment () {
      return this.$store.getters.companyComment;
    },
    filialsList() {
      return this.$store.getters.allFilialCount;
    },
    usersList() {
      return this.$store.getters.allUsersCount;
    },
  },
  methods: {
    onClickOutside () {
      this.more = false
    }
  }
}
</script>

<style scoped lang="scss">

.wrapper {
  padding-top: 31px;
  padding-bottom: 59px;
}
@include md-max {
  .wrapper {
    display: none;
  }

  .dropdown {
    display: none;
  }
}

.dropdown {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1),visibility 0s;
  vertical-align: middle;
  margin-left: 17px;
  margin-right: 5px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.dropdown:hover, .dropdown:after:hover {
  cursor: pointer;
}

.dropdown:after {
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #818c99;
  transform: rotate(-90deg);
  position: absolute;
  cursor: pointer;
}



.more {
  position: absolute;
  top: 10px;
  right: 0px;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(3, 25, 51, 0.13);
  padding: 10px;
  min-width: 330px;

  a {
    font-weight: normal;
    align-items: baseline;
    display: inline-flex;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    vertical-align: middle;
    margin-right: 5px;
    color: $navy;

  }

  .ml {
    margin-left: 15px;
  }

  .mb {
    margin-bottom: 25px;
  }

  .actions {
    font-size: 16px;
    margin-top: 20px;
  }

  .more-list {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      margin-right: 15px;
      font-weight: normal;
      letter-spacing: normal;
      color: $navy;
      &:last-child {
        margin-right: 0;
      }
      a {
        background-color: #1787d8;
        font-weight: 900;
        color: #ffffff;
        border-radius: 50px;
        margin-left: 10px;
        width: 22px;
        height: 22px;
        display: flex;
      }
    }

  }

  //.mb a:after {
  //  content: "+";
  //  background-color: #1787d8;
  //  font-weight: 900;
  //  color: #ffffff;
  //  transform: rotate(-90deg);
  //  border-radius: 100%;
  //  margin-left: 10px;
  //  padding: 1px 3px 1px 3px;
  //  align-items: center;
  //  justify-content: center;
  //  vertical-align: middle;
  //
  //  text-align: center;
  //}


  h3 {
    display: flex;
    font-weight: normal;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 16px;
    li {
      padding: 5px 0px;
      margin: 0px;
    }

  }
}

.company_name  {
  h1 {
    font-size: 22px;
    line-height: 1.27;
    align-items: center;
    flex: 1 0 auto;
    justify-content: inherit;
    position: relative;
  }

  span {
    font-size: 18px;
    line-height: normal;
  }
}

li:not(:first-of-type) {
  margin-left: 16px;
}

.kb-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;



  .kb-link {
    display: inline-block;
    padding: 9px 12px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    color: #818c99;
    letter-spacing: -0.7px;
    border-radius: 4px;
    line-height: normal;

    &.active {
      color: #031933;
      background-color: #f2f8fa;
    }
  }
}
</style>
